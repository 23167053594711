import { usePathname } from "next/navigation";
import { useTranslation } from "react-i18next";

import { AppEnum } from "@/constants";
import {
  APP_LOGO,
  DEFAULT_BRAND_CONFIGURATION,
} from "@/contexts/branding/constants";
import { BrandConfiguration } from "@/contexts/branding/types";
import { useUserInfoQuery } from "@/hooks/queries/useAuthQueries";

import {
  isAOSmithDefault,
  isAOSmithLowes,
  isAOSmithStateWaterHeaters,
  isCPAU,
  isSVCE,
} from "./utils";

export const useDetermineApp = () => {
  const { data: user } = useUserInfoQuery();
  const pathname = usePathname();

  const branding = (() => {
    if (isAOSmithDefault({ pathname })) {
      return AppEnum.AOSmithDefault;
    }
    if (isAOSmithStateWaterHeaters({ pathname })) {
      return AppEnum.AOSmithStateWaterHeaters;
    }
    if (isAOSmithLowes({ pathname })) {
      return AppEnum.AOSmithLowes;
    }
    if (isSVCE({ hostname: location.hostname, user })) {
      return AppEnum.SVCE;
    }
    if (isCPAU(location.hostname)) {
      return AppEnum.CPAU;
    }
    return AppEnum.RockRabbit;
  })();

  return {
    branding,
  };
};

export const useBrandingConfig = (app: AppEnum) => {
  const { t } = useTranslation();

  const BRAND_CONFIGURATIONS: { [key in AppEnum]?: BrandConfiguration } = {
    [AppEnum.SVCE]: {
      brandName: "svce",
      shouldRedirectContractorsToRockRabbit: true,
      contractorListFilter: "svce_eligible_only",
      contractorDirectoryDisclaimer: t("quotable_job_pre_select.disclaimer"),
      translationPrefix: "svce",
      homeContactInfo: {
        title: t("dashboard.contact_our_advisor.header"),
        phoneNumber: t("dashboard.contact_our_advisor.phone.value"),
        email: t("dashboard.contact_our_advisor.email.value"),
        availableHours: t("dashboard.contact_our_advisor.hours.value"),
        liveChatLink: "https://goelectric.svcleanenergy.org/#start",
      },
      knowledgeCenter: {
        ehubInfo: {
          label: t("knowledge_center.visit_svce_message.title"),
          url: "https://svcleanenergy.org/ehub/",
        },
      },
    },
    [AppEnum.CPAU]: {
      brandName: "cpau",
      shouldRedirectContractorsToRockRabbit: true,
      contractorListFilter: "cpau_eligible_only",
      contractorDirectoryDisclaimer: t(
        "quotable_job_pre_select.CPAU.directory_disclaimer"
      ),
      translationPrefix: "cpau",
      homeContactInfo: {
        title: t("dashboard.contact.cpau.header"),
        phoneNumber: "650-713-3411",
        email: "switch@cityofpaloalto.org",
      },
      knowledgeCenter: {
        ehubInfo: {
          label: t("knowledge_center.visit_cpau_message"),
          url: "https://www.cityofpaloalto.org/goelectric",
        },
      },
    },
  };

  return {
    brandConfig: BRAND_CONFIGURATIONS[app] ?? DEFAULT_BRAND_CONFIGURATION,
    logoUrl: APP_LOGO[app] ?? "",
  };
};
