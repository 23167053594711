import TagManager from "react-gtm-module";

import { User } from "@/api/BackendAPI";
import { AOS_BRAND_TO_APP_ENUM, AppEnum } from "@/constants";

import { CPAU_HOSTNAMES, SVCE_HOSTNAMES } from "./constants";

export const isSVCE = ({
  hostname,
  user,
}: {
  hostname: string;
  user: User | undefined;
}) => {
  // src/contexts/featureFlag/index.tsx will block rendering until user is fetched
  if (user?.verified_svce) {
    return true;
  }

  return SVCE_HOSTNAMES.includes(hostname);
};

export const isAOSmithDefault = ({ pathname }: { pathname: string }) => {
  const targetAppEnum = Object.entries(AOS_BRAND_TO_APP_ENUM).find(
    ([, appName]) => appName === AppEnum.AOSmithDefault
  );

  if (!targetAppEnum) {
    return false;
  }

  return new RegExp(`^/aos/${targetAppEnum[0]}`).test(pathname);
};

export const isAOSmithStateWaterHeaters = ({
  pathname,
}: {
  pathname: string;
}) => {
  const targetAppEnum = Object.entries(AOS_BRAND_TO_APP_ENUM).find(
    ([, appName]) => appName === AppEnum.AOSmithStateWaterHeaters
  );

  if (!targetAppEnum) {
    return false;
  }

  return new RegExp(`^/aos/${targetAppEnum[0]}`).test(pathname);
};

export const isAOSmithLowes = ({ pathname }: { pathname: string }) => {
  const targetAppEnum = Object.entries(AOS_BRAND_TO_APP_ENUM).find(
    ([, appName]) => appName === AppEnum.AOSmithLowes
  );

  if (!targetAppEnum) {
    return false;
  }

  return new RegExp(`^/aos/${targetAppEnum[0]}`).test(pathname);
};

export const isCPAU = (hostname: string) => {
  return CPAU_HOSTNAMES.includes(hostname);
};

export const initializeGoogleTagManager = (gtmId: string): void => {
  TagManager.initialize({ gtmId: gtmId });
};
