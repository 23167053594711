import { isDev } from ".";
import i18next, { PostProcessorModule, TFunction } from "i18next";
import ChainedBackend from "i18next-chained-backend";
import LocizeBackend from "i18next-locize-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { setLocale } from "yup";

import { LocaleEnum } from "@/api/BackendAPI";
import enContractors from "@/locales/en/contractors.json";
import enHomeowners from "@/locales/en/homeowners.json";
import esContractors from "@/locales/es/contractors.json";
import esHomeowners from "@/locales/es/homeowners.json";

const localResources = {
  en: {
    contractors: enContractors,
    homeowners: enHomeowners,
  },
  es: {
    contractors: esContractors,
    homeowners: esHomeowners,
  },
};

type PartialYupError = {
  key: string;
  values?: Record<string, any>;
};

export type YupError = {
  key: string;
  values: Record<string, any>;
};

function makeYupError({ key, values }: PartialYupError): YupError {
  return { key, values: values || {} };
}

// config is treating the local resources as a cache to be refreshed from locize.
// it's kind of a combination between the caching approach:
// https://docs.locize.com/more/caching/alternative-caching#browser-caching-with-local-storage
// and the fallback approach:
// https://docs.locize.com/more/backend-fallback#browser-fallback-with-local-bundled-translations
export function configureI18n(userLocale: LocaleEnum, onLoaded: () => void) {
  // by default, backends is just the local resources
  const backend = {
    backends: [resourcesToBackend(localResources)],
    backendOptions: [{}],
  };

  // enable locize backend outside of dev
  if (!isDev()) {
    // @ts-expect-error types differ between backend varieties
    backend.backends = [LocizeBackend, ...backend.backends];
    backend.backendOptions = [
      {
        projectId: "5d3923f3-9ae8-4707-bc7f-19dc7d57aec3",
        version: "latest",
        refLng: "en",
      },
      ...backend.backendOptions,
    ];
  }

  i18next
    .use(ChainedBackend)
    .use(initReactI18next)
    .use(emojiPostProcessor)
    .init(
      {
        lng: !Object.keys(localResources).includes(userLocale)
          ? "en"
          : userLocale,
        ns: ["contractors", "homeowners"],
        defaultNS: "contractors",
        fallbackNS: "contractors",
        fallbackLng: "en",
        compatibilityJSON: "v4",
        interpolation: {
          escapeValue: false, // react already prevents xss
        },
        backend: backend,
        react: {
          useSuspense: false,
        },
        postProcess: userLocale == LocaleEnum.Em ? ["emojis"] : undefined,
      },
      (error) => {
        if (error) console.error(error);
        else onLoaded();
      }
    );

  // based on the suggestion from here:
  // https://github.com/jquense/yup/pull/1371#issuecomment-1598833682
  setLocale({
    mixed: {
      required: () => makeYupError({ key: "yup.mixed.required" }),
      oneOf: ({ values }) =>
        makeYupError({ key: "yup.mixed.oneOf", values: { values } }),
      notOneOf: ({ values }) =>
        makeYupError({
          key: "yup.mixed.notOneOf",
          values: { values },
        }),
      notType: ({ type }) =>
        makeYupError({ key: "yup.mixed.notType", values: { type } }),
      defined: () => makeYupError({ key: "yup.mixed.defined" }),
    },
    string: {
      length: ({ length }) =>
        makeYupError({
          key: "yup.string.length",
          values: { length },
        }),
      min: ({ min }) =>
        makeYupError({ key: "yup.string.min", values: { min } }),
      max: ({ max }) =>
        makeYupError({ key: "yup.string.max", values: { max } }),
      matches: ({ regex }) =>
        makeYupError({
          key: "yup.string.matches",
          values: { regex },
        }),
      email: makeYupError({ key: "yup.string.email" }),
      url: makeYupError({ key: "yup.string.url" }),
      uuid: makeYupError({ key: "yup.string.uuid" }),
    },
    number: {
      min: ({ min }) =>
        makeYupError({ key: "yup.number.min", values: { min } }),
      max: ({ max }) =>
        makeYupError({ key: "yup.number.max", values: { max } }),
      lessThan: ({ less }) =>
        makeYupError({
          key: "yup.number.lessThan",
          values: { less },
        }),
      moreThan: ({ more }) =>
        makeYupError({
          key: "yup.number.moreThan",
          values: { more },
        }),
      positive: makeYupError({ key: "yup.number.positive" }),
      negative: makeYupError({ key: "yup.number.negative" }),
      integer: makeYupError({ key: "yup.number.integer" }),
    },
    date: {
      min: ({ min }) => makeYupError({ key: "yup.date.min", values: { min } }),
      max: ({ max }) => makeYupError({ key: "yup.date.max", values: { max } }),
    },
    boolean: {
      isValue: ({ value }) =>
        makeYupError({
          key: "yup.boolean.isValue",
          values: { value },
        }),
    },
    array: {
      min: ({ min }) => makeYupError({ key: "yup.array.min", values: { min } }),
      max: ({ max }) => makeYupError({ key: "yup.array.max", values: { max } }),
      length: ({ length }) =>
        makeYupError({ key: "yup.array.length", values: { length } }),
    },
    object: {
      noUnknown: ({ unknown }) =>
        makeYupError({
          key: "common.default_error_message",
          values: { unknown },
        }),
    },
  });
}

export function yupErrorT(
  t: TFunction,
  error: YupError | string,
  field: string
): string {
  if (typeof error === "string") {
    error = { key: error, values: {} };
  }

  return t(error.key, { replace: { ...error.values, field } });
}

const emojiPostProcessor: PostProcessorModule = (() => {
  const EMOJI_MAP: { [key: string]: string } = {
    A: "🅰",
    B: "🅱",
    C: "🅲",
    D: "🅳",
    E: "🅴",
    F: "🅵",
    G: "🅶",
    H: "🅷",
    I: "🅸",
    J: "🅹",
    K: "🅺",
    L: "🅻",
    M: "🅼",
    N: "🅽",
    O: "🅾",
    P: "🅿",
    Q: "🆀",
    R: "🆁",
    S: "🆂",
    T: "🆃",
    U: "🆄",
    V: "🆅",
    W: "🆆",
    X: "🆇",
    Y: "🆈",
    Z: "🆉",
  };

  return {
    type: "postProcessor",
    name: "emojis",
    process: (value: string) => {
      const chars = value.toLocaleUpperCase().split("");
      return chars.map((ch) => EMOJI_MAP[ch] ?? ch).join("");
    },
  };
})();
