import {
  QueryCache,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "@tanstack/react-query";
import { type ReactNode, useMemo } from "react";

import { isServer } from "@/utils";
import { logError } from "@/utils/sentry";

interface QueryClientProviderProps {
  children: ReactNode;
}

export const QueryClientProvider = ({ children }: QueryClientProviderProps) => {
  // use useMemo to avoid creating a new instance of QueryClient on every render
  // in case there is an state added to this provider later.
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            retryOnMount: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            if (!isServer()) {
              logError(error, {
                file: window.location.pathname,
              });
            }
          },
        }),
      }),
    []
  );

  return (
    <QueryClientProviderBase client={queryClient}>
      {children}
    </QueryClientProviderBase>
  );
};
