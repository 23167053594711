export const PROJECT_CHECKLIST_QUERY_PARAMS = {
  SELECTED_APPLICATION_ID: "application_id",
  SELECTED_PHASE: "phase_name",
  SELECTED_CHECKLIST_ITEM_ID: "checklist_item_id",
} as const;

export const WIZARD_QUERY_PARAMS = {
  QUOTABLE_CONTRACTOR_IDS: "quotable_contractor_ids",
} as const;

export const AUTH_QUERY_PARAMS = {
  // is it phone or email
  VERIFY_METHOD: "verify_method",
  // the value of the phone or email
  VERIFY_VALUE: "verify_value",
  INVITE_TEAM_CODE: "invite_team_code",
  // this is the url AFTER the user is authenticated
  REDIRECT_URL: "redirect_url",
  // this is the url AFTER the user is signed out
  REDIRECT_URL_AFTER_SIGN_OUT: "redirect_url_after_sign_out",
} as const;
