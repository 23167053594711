import { usePathname } from "next/navigation";
import { useEffect } from "react";
import ReactGA from "react-ga4";

import { PartnerTracker, PartnerTrackers } from "@/constants/partners";
import useAuthStore from "@/store/auth";
import { isProd } from "@/utils";

const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-3DZDJ9CHST";

export const useGoogleAnalyticsBoot = () => {
  const userId = useAuthStore((state) => state.userId);
  const pathname = usePathname();
  const partnerTracker: PartnerTracker | null =
    PartnerTrackers.find((tracker) => pathname === tracker.pathname) ?? null;

  useEffect(() => {
    if (isProd()) {
      ReactGA.initialize([
        {
          trackingId: GOOGLE_ANALYTICS_MEASUREMENT_ID,
        },
        // Support one additional partner tracker, based on the landing page
        ...(partnerTracker ? [partnerTracker.initialize] : []),
      ]);
    }
  }, []);

  useEffect(() => {
    if (isProd() && !!userId) {
      ReactGA.set({ userId });
    }
  }, [userId]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);
};
