import { PrimeReactProvider } from "primereact/api";
import { type ReactNode } from "react";
import { ToastContainer } from "react-toastify";

import { BrandingContextProvider } from "@/contexts/branding";
import { FeatureFlagProvider } from "@/contexts/featureFlag";
import { LocalesProvider } from "@/contexts/localesProvider";
import { QueryClientProvider } from "@/contexts/queryClient";

interface ClientProviderProps {
  children: ReactNode;
}

export const ClientProvider = ({ children }: ClientProviderProps) => {
  return (
    <QueryClientProvider>
      <FeatureFlagProvider>
        <LocalesProvider>
          <BrandingContextProvider>
            <PrimeReactProvider>
              {children}
              <ToastContainer />
            </PrimeReactProvider>
          </BrandingContextProvider>
        </LocalesProvider>
      </FeatureFlagProvider>
    </QueryClientProvider>
  );
};
