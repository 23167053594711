import { useRouter } from "next/router";
import { useEffect } from "react";

import { UserType } from "@/api/BackendAPI";
import { URLS } from "@/constants";
import { useUserInfoQuery } from "@/hooks/queries/useAuthQueries";

export default function Page() {
  const router = useRouter();
  const { data: user } = useUserInfoQuery();

  const userType = user?.user_type;

  useEffect(() => {
    if (router.isReady) {
      if (userType === UserType.IncentiveProvider) {
        router.replace(URLS.appAdmin.applications);
      } else {
        router.replace(URLS.home);
      }
    }
  }, [router, userType]);

  return null;
}
